import React, { useState } from 'react';

// import clsx from 'clsx';

// import { Collapse } from '@material-ui/core';

import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
// import ViewColumnTwoToneIcon from '@material-ui/icons/ViewColumnTwoToneIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GroupIcon from '@material-ui/icons/Group';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { Collapse } from '@material-ui/core';
import clsx from 'clsx';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [pagesOpen, setPagesOpen] = useState(false);
  const togglePages = (event) => {
    setPagesOpen(!pagesOpen);
    event.preventDefault();
  };

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/home'}>
                <span className="sidebar-icon">
                  <DashboardIcon />
                </span>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/appstats'}>
                <span className="sidebar-icon">
                  <DashboardIcon />
                </span>
                Apps
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/users'}>
                <span className="sidebar-icon">
                  <GroupIcon />
                </span>
                User
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/plan'}>
                <span className="sidebar-icon">
                  <ReceiptIcon />
                </span>
                Plans
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/repositories'}>
                <span className="sidebar-icon">
                  <DynamicFeedIcon />
                </span>
                Repositories
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/notifications'}>
                <span className="sidebar-icon">
                  <DynamicFeedIcon />
                </span>
                Notifications
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/sites'}>
                <span className="sidebar-icon">
                  <ReceiptIcon />
                </span>
                Sites
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/netlifycredentials'}>
                <span className="sidebar-icon">
                  <ReceiptIcon />
                </span>
                Netlify Credentials
              </NavLink>
            </li>
          </ul>

          <ul>
            <li>
              <a
                href="#/"
                onClick={togglePages}
                className={clsx({ active: pagesOpen })}>
                <span className="sidebar-icon">
                  <ChevronRightTwoToneIcon />
                </span>
                <span className="sidebar-item-label">App Store</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={pagesOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/apps'}>
                      Applications
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/apps/category'}>
                      Categories
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
