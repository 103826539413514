import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

import { SuspenseLoading } from './layout-components';

import { LeftSidebar } from './layout-blueprints';

import ProtectedRoute from './ProtectedRoute';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const AppsManagementPage = lazy(() => import('./pages/AppsManagement'));
const MarketPlaceManagementPage = lazy(() => import('./pages/AppsManagement/MarketPlace'));
const User = lazy(() => import('./pages/User'));
const PageLogin = lazy(() => import('./pages/PageLogin'));
const PageError404 = lazy(() => import('./pages/PageError404'));
const Plan = lazy(() => import('./pages/Plans'));
const Repositories = lazy(() => import('./pages/Repositories'));
const Notifications = lazy(() => import('./pages/Notifications'));
const SiteStatus = lazy(() => import('./pages/SiteStatus'));
const Apps = lazy(() => import('./pages/Applications'));
const AppCategory = lazy(() => import('./pages/ApplicationCategory'));
const NetlifyAccount = lazy(() => import('./pages/NetlifyAccounts'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {/* Clients Area */}
            <Redirect
              exact
              from={process.env.REACT_APP_D_PREFIX}
              to={process.env.REACT_APP_D_PREFIX + '/home'}
            />
            <Route path={process.env.REACT_APP_D_PREFIX}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/home'}
                    component={Dashboard}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/appstats'}
                    component={AppsManagementPage}
                  />
                   <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps/marketplace'}
                    component={MarketPlaceManagementPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/users'}
                    component={User}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/plan'}
                    component={Plan}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps'}
                    component={Apps}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps/category'}
                    component={AppCategory}
                  />
                    <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/netlifycredentials'}
                    component={NetlifyAccount}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/repositories'}
                    component={Repositories}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/notifications'}
                    component={Notifications}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/sites'}
                    component={SiteStatus}
                  />
                  <Route component={PageError404} />
                </Switch>
              </LeftSidebar>
            </Route>

            {/* Public Area */}
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>
              <Route exact path="/" component={PageLogin} />
            </motion.div>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
