import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['CustomerAccount']
};

const rootReducer = combineReducers({ ...reducers });
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  return createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
}
