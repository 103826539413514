import { axiosIns, axiosIns2 } from '../config/configAxios';
import { resizeImg } from 'utils';
// import { transformErrors } from 'utils';
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const addApplicationSuccess = (apps) => ({
  type: ADD_APPLICATION,
  payload: { apps }
});
export const LOADING_IN_PROGRESS = 'APPS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'APPS_LOADING_SUCCESS';
export const loadingSuccess = (apps) => ({
  type: LOADING_SUCCESS,
  payload: { apps }
});

export const LOADING_FAILURE = 'APPS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});
export const UPLOADING_IN_PROGRESS = 'APPS_UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'APPS_UPLOADING_SUCCESS';
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS
});

export const UPLOADING_FAILURE = 'APPS_UPLOADING_FAILURE';
export const uploadingFailure = () => ({
  type: UPLOADING_FAILURE
});

export const UPDATE_APPS = 'UPDATE_APPS';
export const updateApps = (apps) => ({
  type: UPDATE_APPS,
  payload: { apps }
});
/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [] };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_APPLICATION: {
      const { apps } = payload;

      return { ...state, data: state.data.concat(apps) };
    }
    case LOADING_SUCCESS: {
      const { apps } = payload;

      return {
        ...state,
        isLoading: false,
        data: apps
      };
    }
    case UPDATE_APPS: {
      const { apps } = payload;
      const data = state.data.map((p) => {
        if (p._id === apps._id) return apps;
        return p;
      });
      data.isChanged = true;
      return {
        ...state,
        data,
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    case UPLOADING_FAILURE: {
      return { ...state, isUploading: false };
    }
    case UPLOADING_SUCCESS: {
      return { ...state, isUploading: false };
    }
    case UPLOADING_IN_PROGRESS: {
      return { ...state, isUploading: true };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadApps = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/apps');
    const apps = response.data;
    console.log(apps, 'redux');
    dispatch(loadingSuccess(apps));
  } catch (err) {
    dispatch(loadingFailure());
  }
};
export const getAppStats = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/apps/allapps');
    const apps = response.data;
    console.log(apps, 'redux');
    // dispatch(loadingSuccess(apps));
  } catch (err) {
    dispatch(loadingFailure());
  }
};
export const addApp = (data) => async (dispatch, getState) => {
  // setSubmitting(true);
  data.images = await Promise.all(
    data.images.map(async (img) => await resizeImg(img))
  );
  data.icon = await resizeImg(data.icon);

  const formData = new FormData();
  const ARRAY_KEYS = ['images', 'removeImages', 'features'];
  for (const [key, value] of Object.entries(data)) {
    if (ARRAY_KEYS.some((elem) => elem === key)) {
      if (key === 'features' && value.length < 1) formData.append(key, []);
      value.forEach((val, i) => formData.append(key, val));
    } else if (typeof value === 'object' && key !== 'icon') {
      for (let subKey in value)
        formData.append(`${key}.${subKey}`, value[subKey]);
    } else formData.append(key, value);
  }
  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/apps', formData);
    if (res.status === 201) {
      dispatch(addApplicationSuccess(res.data));
      // setStatus({ success: true });
      // resetForm({});
      // toggleModal();
    }
  } catch (err) {
    console.log(err.response.data.errors);
    // console.log()
    // setErrors(transformErrors(err.response.data.errors));
    // setSubmitting(false);
    // if (err.response.status === 400) {
    //   seterror({ status: true, msg: err.response.data.errors[0].msg });
    // }
  } finally {
    // setSubmitting(false);
  }
};

export const updateAppById = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  data.images = await Promise.all(
    data.images.map(async (img) => {
      if (typeof img === 'object') {
        const resizedImg = await resizeImg(img);
        return resizedImg;
      }
      return img;
    })
  );

  const formData = new FormData();
  const ARRAY_KEYS = ['images', 'deleteImages','features'];
  for (const [key, value] of Object.entries(data)) {
    if (ARRAY_KEYS.some((elem) => elem === key)) {
      if (key === 'features' && value.length < 1) formData.append(key, []);
      value.forEach((val, i) => formData.append(key, val));
    } else if (typeof value === 'object' && key !== 'icon') {
      for (let subKey in value)
        formData.append(`${key}.${subKey}`, value[subKey]);
    } else formData.append(key, value);
  }
  formData.set('isChanged', true);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(`/apps/${data._id}`, formData);
    const newProduct = response.data;

    dispatch(updateApps(newProduct));
  } catch (err) {
    // err.response.data.errors
    dispatch(uploadingFailure());
    throw err;
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getAllApps = (state) => state.Apps.data;
export const getIsLoadingApps = (state) => state.Apps.isLoading;
export const getIsUploadingApps = (state) => state.Apps.isUploading;
