import { axiosIns, axiosIns2 } from '../config/configAxios';
import { transformErrors } from 'utils';
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const addAccountSuccess = (account) => ({
  type: ADD_ACCOUNT,
  payload: { account }
});

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const deleteAccountSuccess = (id) => ({
  type: DELETE_ACCOUNT,
  payload: { id }
});

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const updateAccountSuccess = (account) => ({
  type: UPDATE_ACCOUNT,
  payload: { account }
});

export const LOADING_IN_PROGRESS = 'ACCOUNT_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'ACCOUNT_LOADING_SUCCESS';
export const loadingSuccess = (account) => ({
  type: LOADING_SUCCESS,
  payload: { account }
});

export const LOADING_FAILURE = 'ACCOUNT_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [], noAccountCount: 0 };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_ACCOUNT: {
      const { account } = payload;

      return { ...state, data: state.data.concat(account) };
    }

    case DELETE_ACCOUNT: {
      const { id } = payload;
      return { ...state, data: state.data.filter((c) => c._id !== id) };
    }

    case UPDATE_ACCOUNT: {
      const { account } = payload;
      const newData = state.data.map((c) => {
        if (c._id === account._id) return { ...c, ...account };
        return c;
      });
      return { ...state, data: newData };
    }

    case LOADING_SUCCESS: {
      const { account } = payload;
      return {
        ...state,
        isLoading: false,
        data: account
      };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadAccounts = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/netlifycredential');
    const accounts = response.data;
    console.log(accounts);
    dispatch(loadingSuccess(accounts));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const addAccount = (data, props) => async (dispatch, getState) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/netlifycredential', data);
    if (res.status === 201) {
      dispatch(addAccountSuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err.response.data.errors);
    // console.log()
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
    // if (err.response.status === 400) {
    //   seterror({ status: true, msg: err.response.data.errors[0].msg });
    // }
  } finally {
    setSubmitting(false);
  }
};

export const deleteAccount = (accountId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.delete(`/netlifycredential/${accountId}`);

    if (res.status === 204) dispatch(deleteAccountSuccess(accountId));
  } catch (err) {
    console.log(err);
  }
};

export const updateAccount = (cId, data, props) => async (
  dispatch,
  getState
) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.put(`/netlifycredential/${cId}`, data);
    if (res.status === 200) {
      dispatch(updateAccountSuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err);
    setErrors(transformErrors(err.response.data.errors));

    setSubmitting(false);
  } finally {
    setSubmitting(false);
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getAllAccounts = (state) => state.NetlifyAccount.data;
export const getIsLoadingAccounts = (state) => state.NetlifyAccount.isLoading;
export const getNoAccountCount = (state) => state.NetlifyAccount.noAccountCount;
