import ThemeOptions from './ThemeOptions';
import UserAccount from './UserAccount';
import CustomerAccount from './CustomerAccount';
import Plans from './Plans';
import Notification from './Notification';
import Apps from './Apps';
import AppCategory from './AppCategory';
import NetlifyAccount from './NetlifyAccount';

export default {
  ThemeOptions,
  UserAccount,
  CustomerAccount,
  Plans,
  Notification,
  Apps,
  AppCategory,
  NetlifyAccount
};
