import { axiosIns } from 'config/configAxios';

const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const addNotification = (data) => ({
  type: ADD_NOTIFICATION,
  payload: { data }
});
const DELETE_NOTIFICATION = 'DELETE NOTIFICATION';
const deleteNotifications = (notification) => ({
  type: DELETE_NOTIFICATION,
  payload: { notification }
});
const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const updateNotifications = (notification) => ({
  type: UPDATE_NOTIFICATION,
  payload: { notification }
});
const LOADING_NOTIFICATIONS_IN_PROGRESS = 'LOADING_NOTIFICATIONS_IN_PROGRESS';
const LOADING_NOTIFICATIONS_SUCCESS = 'LOADING_NOTIFICATIONS_SUCCESS';
export const loadingNotificationssInProgress = () => ({
  type: LOADING_NOTIFICATIONS_IN_PROGRESS
});
export const loadingNotificationsSuccessful = (data) => ({
  type: LOADING_NOTIFICATIONS_SUCCESS,
  payload: { data }
});
export const NOTIFICATION_UPLOADING_IN_PROGRESS =
  'NOTIFICATION_UPLOADING_IN_PROGRESS';
const uploadingInProgress = () => ({
  type: NOTIFICATION_UPLOADING_IN_PROGRESS
});
export const NOTIFICATION_UPLOADING_SUCCESS = 'NOTIFICATION_UPLOADING_SUCCESS';
const uploadingSuccess = () => ({
  type: NOTIFICATION_UPLOADING_SUCCESS
});

export const NOTIFICATION_UPLOADING_FAILURE = 'NOTIFICATION_UPLOADING_FAILURE';
const uploadingFailure = () => ({
  type: NOTIFICATION_UPLOADING_FAILURE
});

const initialState = {
  data: [],
  error: {},
  isLoadingNotification: false,
  isUploading: false
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_NOTIFICATIONS_SUCCESS: {
      const { data } = payload;
      return { ...state, isLoadingNotification: false, data: data };
    }
    case LOADING_NOTIFICATIONS_IN_PROGRESS: {
      return { ...state, isLoadingNotification: true };
    }
    case ADD_NOTIFICATION: {
      const { data } = payload;
      return {
        ...state,
        data: [...state.data, data],
        isLoadingNotification: false,
        isUploading: false
      };
    }
    case UPDATE_NOTIFICATION: {
      const { notification } = payload;
      const data = state.data.map((p) => {
        if (p._id === notification._id) return notification;
        return p;
      });
      return { ...state, data, isUploading: false };
    }
    case DELETE_NOTIFICATION: {
      const { notification } = payload;
      const data = state.data.filter((p) => p._id !== notification._id);
      return { ...state, data, isUploading: false };
    }
    case NOTIFICATION_UPLOADING_FAILURE: {
      return { ...state, isUploading: false };
    }
    case NOTIFICATION_UPLOADING_SUCCESS: {
      return { ...state, isUploading: false };
    }
    case NOTIFICATION_UPLOADING_IN_PROGRESS: {
      return { ...state, isUploading: true };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadNotifications = () => async (dispatch, getState) => {
  dispatch(loadingNotificationssInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.get('/notification');
    const notification = response.data;
    dispatch(loadingNotificationsSuccessful(notification));
  } catch (e) {}
};

export const addNotifications = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.post('/notification', data);
    const plan = response.data;
    dispatch(addNotification(plan));
    dispatch(uploadingSuccess());
  } catch (e) {
    dispatch(uploadingFailure());
  }
};
export const updateNotification = (id, data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.put('/notification/' + id, data);
    const plan = response.data;
    dispatch(updateNotifications(plan));
    dispatch(uploadingSuccess());
  } catch (e) {
    dispatch(uploadingFailure());
  }
};
export const deleteNotification = (id) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    console.log(id)
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.delete('/notification/' + id);
    const plan = response.data;
    dispatch(deleteNotifications(plan));
    dispatch(uploadingSuccess());
  } catch (e) {
    dispatch(uploadingFailure());
  }
};

export const getNotifications = (state) => state.Notification.data;
export const getIsLoadingNotifications = (state) =>
  state.Notification.isLoadingNotification;

export const getIsUploadingNotifications = (state) => state.Notification.isUploading;
