import { axiosIns } from '../config/configAxios';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const LOADING_IN_PROGRESS = 'CUSTOMERS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'CUSTOMERS_LOADING_SUCCESS';
export const loadingSuccess = (customers) => ({
  type: LOADING_SUCCESS,
  payload: { customers }
});

export const LOADING_FAILURE = 'CUSTOMERS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

export const UPLOADING_IN_PROGRESS = 'UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'UPLOADING_SUCCESS';
export const uploadingSuccess = (customer) => ({
  type: UPLOADING_SUCCESS,
  payload: { customer }
});

export const UPLOADING_FAILURE = 'UPLOADING_FAILURE';
export const uploadingFailure = (error) => ({
  type: UPLOADING_FAILURE,
  payload: { error }
});
export const SET_USER_CHANGED = 'SET_USER_CHANGED';
export const setUserChanged = () => ({
  type: SET_USER_CHANGED
});
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const deleteUserSubscription = (data) => ({
  type: SET_USER_CHANGED,
  payload: data
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = {
  isLoading: false,
  // isUploading: false,
  data: [],
  isChanged: false,
  isUploadingCustomer: false,
  error: false
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_SUCCESS: {
      const { customers } = payload;
      return { ...state, isLoading: false, data: customers };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    case UPLOADING_IN_PROGRESS:
      return { ...state, isUploadingCustomer: true };
    case UPLOADING_FAILURE:
      return { ...state, isUploadingCustomer: false };
    case UPLOADING_SUCCESS: {
      const { customer } = payload;
      const customers = state.data.map((cust, index) => {
        if (cust._id === customer._id) {
          return { ...cust, status: customer.status };
        }
        return cust;
      });
      const newState = {
        ...state,
        isUploadingCustomer: false,
        data: customers,
        isChanged: true
      };
      return Object.assign({}, newState);
    }
    case DELETE_SUBSCRIPTION: {
      const { userId } = payload;
      const customers = state.data.map((cust, index) => {
        if (cust._id === userId) {
          return { ...cust, subscriptionId: null };
        }
        return cust;
      });
      const newState = {
        ...state,
        isUploadingCustomer: false,
        data: customers,
        isChanged: true
      };
      return Object.assign({}, newState);
    }
    case SET_USER_CHANGED:
      return { ...state, isChanged: !state.isChanged };
    default:
      return state;
  }
}

export const loadCustomers = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/users');
    const users = response.data;

    dispatch(loadingSuccess(users));
  } catch (err) {
    dispatch(loadingFailure());
  }
};
export const changeCustomerStatus = (id, data) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingInProgress);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
      const response = await axiosIns.put(`/users/status/${id}`, {
        status: data
      });

      const user = response.data;

      dispatch(uploadingSuccess(user));
    } catch (e) {
      dispatch(loadingFailure());
    }
  } catch (err) {
    dispatch(uploadingFailure());
  }
};

export const assignTrail = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
      const response = await axiosIns.post(`/subscription/trail`, {
        ...data
      });

      const user = response.data;

      dispatch(uploadingSuccess(user));
      return response;
    } catch (e) {
      dispatch(loadingFailure());
      return e;
    }
  } catch (err) {
    dispatch(uploadingFailure());
    return err;
  }
};
export const extendTrial = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axiosIns.post(`/subscription/extendtrail`, {
        ...data
      });

      const user = response.data;

      dispatch(uploadingSuccess(user));
      return response;
    } catch (e) {
      dispatch(loadingFailure());
      return e;
    }
  } catch (err) {
    dispatch(uploadingFailure());
    return err;
  }
};
export const upgradeSubscription = ({ invoiceId, subscriptionId }) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingInProgress());
  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return axiosIns
    .post('/subscription/update-subscription', {
      newPriceId: invoiceId,
      subscriptionId: subscriptionId
    })

    .then((response) => {
      dispatch(uploadingSuccess(response.data));
      return response;
    })
    .catch((e) => {
      return e;
    });
};
export const cancelSubscription = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
      const response = await axiosIns.post(
        `/subscription/` + data.subscriptionId.subscriptionId,
        { reason: data.reason }
      );

      const res = response.data;

      if (res.msg === 'success') {
        dispatch(deleteUserSubscription(data.userId));
      }

      return response;
    } catch (e) {
      dispatch(loadingFailure());
    }
  } catch (err) {
    dispatch(uploadingFailure());
  }
};
export const getCustomers = (state) => state.CustomerAccount.data;
export const isLoadingCustomers = (state) => state.CustomerAccount.isLoading;
export const isUploadingCustomer = (state) =>
  state.CustomerAccount.isUploadingCustomer;
export const isChanged = (state) => state.CustomerAccount.isChanged;
