import { axiosIns, axiosIns2 } from '../config/configAxios';
import { transformErrors } from 'utils';
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const addCategorySuccess = (category) => ({
  type: ADD_CATEGORY,
  payload: { category }
});

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const deleteCategorySuccess = (id) => ({
  type: DELETE_CATEGORY,
  payload: { id }
});

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const updateCategorySuccess = (category) => ({
  type: UPDATE_CATEGORY,
  payload: { category }
});

export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export const addSubCategorySuccess = (subcategory) => ({
  type: ADD_SUBCATEGORY,
  payload: { subcategory }
});


export const LOADING_IN_PROGRESS = 'CATEGORIES_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'CATEGORIES_LOADING_SUCCESS';
export const loadingSuccess = (categories) => ({
  type: LOADING_SUCCESS,
  payload: { categories }
});

export const LOADING_FAILURE = 'CATEGORIES_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [], noCategoryCount: 0 };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CATEGORY: {
      const { category } = payload;

      return { ...state, data: state.data.concat(category) };
    }

    case DELETE_CATEGORY: {
      const { id } = payload;
      return { ...state, data: state.data.filter((c) => c._id !== id) };
    }

    case UPDATE_CATEGORY: {
      const { category } = payload;
      const newData = state.data.map((c) => {
        if (c._id === category._id) return { ...c, ...category };
        return c;
      });
      return { ...state, data: newData };
    }

    case LOADING_SUCCESS: {
      const { categories } = payload;
      return {
        ...state,
        isLoading: false,
        data: categories
      };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadCategories = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/apps/categories');
    const categories = response.data;
    console.log(categories);
    dispatch(loadingSuccess(categories));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const addCategory = (data, props) => async (dispatch, getState) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/apps/categories', data);
    if (res.status === 201) {
      dispatch(addCategorySuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err.response.data.errors);
    // console.log()
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
    // if (err.response.status === 400) {
    //   seterror({ status: true, msg: err.response.data.errors[0].msg });
    // }
  } finally {
    setSubmitting(false);
  }
};

export const deleteCategory = (categoryId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.delete(`/apps/categories/${categoryId}`);

    if (res.status === 204) dispatch(deleteCategorySuccess(categoryId));
  } catch (err) {
    console.log(err);
  }
};

export const updateCategory = (cId, data, props) => async (
  dispatch,
  getState
) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.put(`/apps/categories/${cId}`, data);
    if (res.status === 200) {
      dispatch(updateCategorySuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err);
    setErrors(transformErrors(err.response.data.errors));

    setSubmitting(false);
  } finally {
    setSubmitting(false);
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getAllCategories = (state) => state.AppCategory.data;
export const getIsLoadingCategories = (state) => state.AppCategory.isLoading;
export const getNoCategoryCount = (state) => state.AppCategory.noCategoryCount;
