import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Routes from './Routes';
import { SuspenseLoading } from './layout-components';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';

const store = configureStore();
const persistor = persistStore(store);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<SuspenseLoading />}>
          <BrowserRouter basename="/">
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
