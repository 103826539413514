import { axiosIns } from '../config/configAxios';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN_PROVIDER = 'USER_LOGIN_PROVIDER';
export const loginByProvider = (data) => ({
  type: USER_LOGIN_PROVIDER,
  payload: { data }
});
export const UPDATE_USER = 'UPDATE_USER';
export const updateUserAction = (data) => ({
  type: UPDATE_USER,
  payload: { data }
});
export const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS';
export const updateUserNotificationAction = (value) => ({
  type: UPDATE_USER_NOTIFICATIONS,
  payload: { value }
});
export const UPDATE_USER_EMAIL_SETTINGS = 'UPDATE_USER_EMAIL_SETTINGS';
export const updateEmailAction = (value) => ({
  type: UPDATE_USER_EMAIL_SETTINGS,
  payload: { value }
});
export const UPLOADING_IN_PROGRESS = 'PRODUCTS_UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'PRODUCTS_UPLOADING_SUCCESS';
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS
});

export const UPLOADING_FAILURE = 'PRODUCTS_UPLOADING_FAILURE';
export const uploadingFailure = () => ({
  type: UPLOADING_FAILURE
});
export const VERIFICATION_STATUS = 'VERIFICATION_STATUS';
export const verificationStatus = () => ({
  type: VERIFICATION_STATUS
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */
const initialState = {
  isAuthenticated: false,

  data: {},
  error: {},
  isUploading: false
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN_PROVIDER:
    case USER_LOGIN: {
      const { data } = payload;
      return {
        ...state,
        data,
        isAuthenticated: true,
        isVerified: data.status
      };
    }
    case UPDATE_USER: {
      const { data } = payload;
      const newData = { ...state.data, ...data };
      return { ...state, data: newData };
    }
    case UPDATE_USER_EMAIL_SETTINGS: {
      const { value } = payload;
      const data = { ...state.data, ...{ emailNotification: value } };
      return { ...state, data };
    }
    case UPDATE_USER_NOTIFICATIONS: {
      const { value } = payload;
      const data = { ...state.data, ...{ customSettings: value } };
      return { ...state, data };
    }
    case USER_LOGOUT: {
      const email = state.data.email;
      return {
        ...state,
        data: { email },
        isAuthenticated: false,
        isVerified: 'login'
      };
    }
    case UPLOADING_SUCCESS: {
      return { ...state, isUploading: false };
    }
    case UPLOADING_IN_PROGRESS:
      return { ...state, isUploading: true };
    case UPLOADING_FAILURE:
      return { ...state, isUploading: false };
    case VERIFICATION_STATUS:
      return { ...state, isVerified: 'login' };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */

export const login = (data) => (dispatch, getState) => {
  return axiosIns
    .post('/login', data)
    .then((res) => dispatch({ type: USER_LOGIN, payload: { data: res.data } }));
};
export const fetchUser = (data) => (dispatch, getState) => {
  axiosIns.defaults.headers.common['Authorization'] = `Bearer ${data}`;
  return axiosIns
    .get('/users')
    .then((res) => dispatch({ type: USER_LOGIN, payload: { data: res.data } }));
};
export const updateUser = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const newdata = await axiosIns.put(`/users`, data);

    dispatch(updateUserAction(newdata.data));
    dispatch(uploadingSuccess());
  } catch (err) {
    dispatch(uploadingFailure());
    console.log(err);
  }
};

export const updateEmailSettings = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await axiosIns.put(`/users/emailNotification`, { data });

    dispatch(updateEmailAction(data));
    dispatch(uploadingSuccess());
  } catch (err) {
    dispatch(uploadingFailure());
    console.log(err);
  }
};

export const logout = () => (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGOUT, payload: {} });
  } catch (err) {
    console.log(err);
  }
};

export const updateNotificationsSettings = (data) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    await axiosIns.put(`/users/notifications`, { data });
    // const newProduct = response.data;

    dispatch(updateUserNotificationAction(data));
    dispatch(uploadingSuccess());
  } catch (err) {
    dispatch(uploadingFailure());
    console.log(err);
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */
export const getUser = (state) => state.UserAccount.data;
export const getEmailStatus = (state) =>
  state.UserAccount.data.emailNotification;
export const getIsAuthenticated = (state) => state.UserAccount.isAuthenticated;
export const getVerificationStatus = (state) => state.UserAccount.isVerified;

export const getIsUploadingUser = (state) => state.UserAccount.isUploading;
