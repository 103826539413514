import { Button, List, ListItem, Menu } from '@material-ui/core';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
// import CountUp from 'react-countup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import avatar4 from '../../assets/images/avatars/avatar4.jpg';
import { getUser, logout } from '../../reducers/UserAccount';

const HeaderUserbox = ({ loggedInUser, logoutUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutUser();
    history.push('/');
  };
  const handleProfilePage = () => {
    handleClose();
    history.push('/app/profile');
  };
  const handleSettingPage = () => {
    handleClose();
    history.push('/app/settings');
  };

  return (
    <>
      <div className="user-box position-relative mr-2">
        <Button
          onClick={handleClick}
          className="btn-link p-0 text-left d-flex align-items-center">
          <div className="d-block p-0 avatar-icon-wrapper">
            <div className="badge badge-success badge-circle p-top-a">
              Online
            </div>
            <div className="avatar-icon rounded-sm">
              <img
                src={
                  loggedInUser.image == null
                    ? avatar4
                    : `${process.env.REACT_APP_ASSETS_BASE_URL}/${loggedInUser.image}`
                }
                alt="..."
              />
            </div>
          </div>
          <div className="d-none d-xl-block pl-2">
            <span className="text-danger">
              <small>
                {' '}
                {loggedInUser?.lastName ?? ''} {loggedInUser?.firstName ?? ''}
              </small>
            </span>
            <div className="font-weight-bold">
              {loggedInUser?.lastName ?? ''} {loggedInUser?.firstName ?? ''}
            </div>
          </div>
          <span className="pl-1 pl-xl-3">
            <ExpandMoreIcon className="opacity-5" />
          </span>
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorEl)}
          classes={{ list: 'p-0' }}
          onClose={handleClose}>
          <div className="dropdown-menu-lg overflow-hidden p-0">
            <div className="d-flex p-4">
              <div className="avatar-icon rounded mr-3">
                <img
                  src={
                    loggedInUser.image == null
                      ? avatar4
                      : `${process.env.REACT_APP_ASSETS_BASE_URL}/${loggedInUser.image}`
                  }
                  alt="..."
                />
              </div>
              <div>
                <h6 className="font-weight-bold mb-1 text-black">
                  {loggedInUser?.lastName ?? ''} {loggedInUser?.firstName ?? ''}
                </h6>
                <p style={{ fontSize: '10px' }} className="text-black-50 mb-0">
                  {loggedInUser?.email ?? 'brian@example.com'}
                </p>
              </div>
            </div>

            <div className="divider" />
            <List
              component="div"
              className="nav-neutral-first nav-pills-rounded flex-column p-3">
              <ListItem button onClick={() => handleSettingPage()}>
                <div className="mr-2">
                  <SettingsTwoToneIcon />
                </div>
                <span className="font-size-md">Settings</span>
              </ListItem>
              <ListItem button onClick={() => handleProfilePage()}>
                <div className="mr-2">
                  <VerifiedUserTwoToneIcon />
                </div>
                <span className="font-size-md">Profile</span>
              </ListItem>
            </List>
            <div className="divider" />
            <List
              component="div"
              className="nav-neutral-danger nav-pills-rounded flex-column p-3">
              <ListItem button onClick={() => handleLogout()}>
                <div className="mr-2">
                  <ExitToAppTwoToneIcon />
                </div>
                <span>Log out</span>
              </ListItem>
            </List>
          </div>
        </Menu>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
